import React from 'react';
import styled from 'styled-components';

const StyledBasicContainer = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
    box-sizing: border-box;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.background)};
`;

const BasicContainer = ({
    as = 'div',
    children,
    width = 'auto',
    height = 'auto',
    style,
    padding = '0px',
    margin = '0px',
    className,
    onClick = null,
    onMouseEnter = null,
    onMouseLeave = null,
    backgroundColor,
}) => {
    return (
        <StyledBasicContainer
            onClick={onClick}
            className={className}
            as={as}
            width={width}
            height={height}
            style={style}
            padding={padding}
            margin={margin}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            backgroundColor={backgroundColor}
        >
            {children}
        </StyledBasicContainer>
    );
};

export default BasicContainer;
