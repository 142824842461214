Object.assign(String.prototype, {
    withOpacity(opacity = 1) {
        return this + String(Math.ceil(255 * opacity).toString(16)).padStart(2, '0');
    },
});

const COLORS = {
    WHITE: '#FFFFFF',
    LIGHT_DIVIDER: '#CECECE',
    DARK_DIVIDER: '#484848',
    BLACK: '#000000',
    RED: '#FF0000',
    TRACK_GREY: '#272727',
    LIGHT_DOTT: '#D5D5D5',
    DARK_DOTT: '#898989',
    GREY: '#B1B1B1',
    IMAGE_PICKER: '#ACACAC',
};

export default COLORS;
