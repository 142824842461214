import COLORS from '../constants/COLORS';

const common = {
    active: COLORS.RED,
};

export const ligthTheme = {
    colors: {
        background: COLORS.WHITE,
        constrast: COLORS.BLACK,
        divider: COLORS.LIGHT_DIVIDER,
        buttonContrast: COLORS.GREY,
        scrollbar: COLORS.LIGHT_DIVIDER,
        ...common,
    },
};

export const darkTheme = {
    colors: {
        background: COLORS.BLACK,
        contrast: COLORS.WHITE,
        divider: COLORS.DARK_DIVIDER,
        buttonContrast: COLORS.WHITE,
        scrollbar: COLORS.TRACK_GREY,
        ...common,
    },
};
