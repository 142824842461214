import React from 'react';
import styled, { useTheme } from 'styled-components';

const StyledTitle = styled.h1`
    font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
    color: ${(props) => props.color};
    font-weight: ${(props) => props.fontWeight};
    margin-top: 0px;
    margin-bottom: ${(props) => props.marginBottom};
    margin: ${(props) => (props.noMargin ? '0px' : null)};
    font-family: 'CynthoNextSlab-Light';
    font-weight: normal;
`;

const Title = ({ fontSize, color, fontWeight = 'bold', children, as = 'h1', marginBottom, noMargin, text, style, className }) => {
    const theme = useTheme();
    const usedColor = color ?? theme.colors.constrast;

    return (
        <StyledTitle
            className={className}
            noMargin={noMargin}
            marginBottom={marginBottom}
            as={as}
            fontWeight={fontWeight}
            fontSize={fontSize}
            color={usedColor}
            style={style}
        >
            {text || children}
        </StyledTitle>
    );
};

export default Title;
